/* #newcustomer-container.kwt{
    justify-content: center;
    background-color: aqua;
} */

#body-kwt{
    width: 95%;
    display: flex;
    flex-direction: column;
    /* background-color: aquamarine; */
    align-items: center;
    border: 2px black solid;
}

.kwitansi-head .logo-img{
    width: 115px;
    /* background-color: aqua; */
}

.kwitansi-head .logo-img img{
    width: 100%;
    margin-top: 10px;
}

#cetak-container.kwt{
    /* border: 2px black solid; */
    margin-top: 30px;
}

.kwitansi-head{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    /* background-color: blue; */
    
}

.kwitansi-head .head-address{
    /* background-color: rgb(108, 128, 128); */
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.head-address p{
    margin-top: 0px;
}

.kwitansi-head .desc-nofren{
    /* background-color: blueviolet; */
    width:30%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    
}

.desc-nofren table{
    width: 100%;
    /* background-color: brown; */
}

.title-kwitansi{
    border: 1px black solid;
    margin-top: 20px;
    width: 95%;
    display: flex;
    justify-content: center;
    height: 20px;
    align-items: center;
}

.hand-over{
    width: 95%;
    padding: 0px 10px;
    margin: 5px 0;
}

.hand-over table{
    min-width: 250px;
    /* background-color: aqua; */
    border-spacing: 0 8px;
    
}

.hand-over table tr td{
    /* border: 3px solid black; */
    min-height: 100px;
}

.container-body{
    width: 94.9%;
    border: 1px black solid;
    /* min-height: 200px; */
    /* background-color: aqua; */
    /* margin-bottom: 200px; */
    border-top: 0;
}



.body-desc.body-desckwt{
    /* min-height: 200px; */
    /* background-color: aqua; */
    width: 100%;
    border: none;
}

.header-desc.header-desckwt{
    width: 95%;
}

.body-desc.body-desckwt text{
    display: flex;
    align-items: start;
    justify-content: space-between;
    /* max-height: 100px; */
    /* background-color: rgb(8, 112, 112); */
    border: none;
    
}

.body-desc.body-desckwt text.no{
    display: flex;
    align-items: start;
    justify-content: center;
    border-right: 1px solid black;
    /* background-color: aquamarine; */
    width: 15.1%;
}
.body-desc.body-desckwt text.keterangan{
    border-right: 1px solid black;
    justify-content: start;
}

/* .header-desc.header-desckwt{} */

.body-desc.body-desckwt text.no.asd{
    height: 70px;
}
.body-desc.body-desckwt text.keterangan.asd{
    height: 70px;
}

.total.kwt{
    width: 94.9%;
    margin-top: 0;
    border-top: none;
}

.kwitansi-foot{
    /* background-color: aqua; */
    width: 95%;
    margin: 10px 0;
    display: flex;
}

.kwitansi-foot .terbilang{
    display: flex;
    width: 70%;
    align-items: flex-start;
    /* background-color: aquamarine; */
}

.terbilang .terbilang-group{
    /* background-color: blue; */
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.terbilang .terbilang-group p, h4{
    font-size: 13px;
}

.kwitansi-foot .sign{
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 100px;
    border: none;
}

.kwitansi-foot .sign .sign-name{
    /* background-color: blue; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sign-name p{
    /* background-color: black; */
    width: 100%;
    border-bottom: 1px solid black;
    text-align: center;
}

.total-nominal.kwt{
    width: 39%;
}

