/* .greyGridTable.unit{
    max-height: 10px;
    overflow-y: auto;
} */

/* .greyGridTable{
position: relative;
} */

.list-antrian-report{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 25px;
}

table.greyGridTable-report {
    /* border: 2px solid #FFFFFF; */
    /* max-width: 100%;
    min-width: 100%; */
    text-align:justify;
    border-collapse: collapse;
    border-bottom: black solid 1px;
    overflow-x: auto;
    /* position: relative; */
    
  }

  table.greyGridTable-report td, table.greyGridTable-report th {
    border: 1px solid #000000;
    padding: 2px 4px;
    word-wrap: break-word;
    /* max-width: 300px; */
    min-width: 80px;
    text-align: center;
    font-size: 12px;
  }

  /* table.greyGridTable-report td.no, table.greyGridTable th.no {
    max-width: 30px;
    min-width: 30px;
    background-color: #154734;
  } */

  table.greyGridTable-report thead {
    background: #fce9e9;
    border-bottom: 4px solid #333333;
    /* position: sticky;
    top: 100px; */
    /* z-index: 9999; */
  }

  table.greyGridTable-report thead th {
    font-size: 12px;
    font-weight: bold;
    color: #333333;
    text-align: center;
    /* border-left: 2px solid #333333; */
  }


  table.greyGridTable-report thead{
    position: sticky;
    top: 0;
    border: 1px black solid;
    /* z-index: 10000; */
}



/* table.greyGridTable-report .row-body td.note{
    position: sticky;
    left: 0;
    background-color: rgb(175, 56, 56);
} */

table.greyGridTable-report td.first-col:hover{
    cursor: pointer;
    }

table.greyGridTable-report tbody td:hover:not(.first-col, .noted){
    background-color: #f6d4d4;
    transform: scale(1,1);
    transition: all 0.2s;
    cursor: pointer;
}

/* table.greyGridTable-report tbody td:active{
    
    transform: scale(2,2);
    transition: all 0.2s;
    
} */

table.greyGridTable-report tbody tr:hover{
    background-color: #c5c5c5;
    transition: all 0.2s;
    
}


/* .greyGridTable thead td{
    border-top: none;
} */
/* .list-antrian.report .greyGridTable tbody td{
    text-align: end;
}

.greyGridTable tbody td span{
    text-align: start;
} */

.row-head td:not(.noted){
    font-weight: bold;
    background-color: rgb(209, 209, 209);
}

table.greyGridTable-report #ipl-unit{
    display:none;
}

table.greyGridTable-report #internet-unit{
    display: none;
}

table.greyGridTable-report tbody td input{
    height: 100%;
    margin: 0;
    border: none;
    /* width: 10000px; */
    /* background-color: #f6d4d4; */
    color: #000000;
    border-radius: 0;
}

table.greyGridTable-report tbody .row-head td:last-child{
    padding: 0;
}
table.greyGridTable-report tbody .row-body td:last-child{
    padding: 0;
}




/* .list-antrian.report{
    overflow-y: auto;
} */

/* scroll set start */

/* .list-antrian.report::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  .list-antrian.report::-webkit-scrollbar-track {
    background: #154734;
  }
  
  .list-antrian.report::-webkit-scrollbar-thumb {
    background-color: #f5eaa5;
    border-radius: 6px;
    border: 3px solid #154734;
  } */

  /* scroll set end */

