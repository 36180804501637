#dashboard-main{
    /* height: 100vh; */
    /* background-color: aqua; */
    overflow: auto;
    justify-content: center;
    align-items: center;
    max-height: calc(100vh);
    width: 100%;
}
#dashboard-main::-webkit-scrollbar {
    width: 11px;
  }
  
  #dashboard-main::-webkit-scrollbar-track {
    background: #154734;
  }
  
  #dashboard-main::-webkit-scrollbar-thumb {
    background-color: #f3f0dd;
    border-radius: 6px;
    border: 3px solid #154734;
  }

  .card .info h2,h3{
    color: black;
  }

.card-group{
    /* margin: 40px; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 100px;
    /* background-color: #5AB15E; */
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.card-group .card{
    border: 1px solid black;
    width: 300px;
    height: 150px;
    background-color: white;
    border-radius: 10px;
    box-shadow: -1px -1px 13px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: -1px -1px 13px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px -1px 13px 0px rgba(0,0,0,0.75);
    display: flex;
    gap: 20px;
    /* margin-bottom: 20px; */
}

.card .icon-flag{
    border: 1px solid black;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    height: 90px;
    background-color: rgb(121, 143, 252);
    border-radius: 5px;
    margin-top: -20px;
    box-shadow: -1px -1px 13px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: -1px -1px 13px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px -1px 13px 0px rgba(0,0,0,0.75);
}

.icon-flag.unpaid{
    background-color: #E32F6E;
}
.icon-flag.paid{
    background-color: #5AB15E;
}

.icon-flag img{
    width: 50%;
}

.card .info{
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
}