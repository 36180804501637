.input-childgroup .check-group{
    display: flex;
    flex-direction: row;
    /* background-color: black; */
    align-items: center;
    max-width: 30px;
}

.check-group input{
    width: 20px;
    background-color: rgb(23, 39, 39);
    margin-right: 10px;
    transform: scale(1);

}

input.ppn-check{
    background-color: aqua;
}

.input-childgroup{
    /* background-color: aqua; */
    display: flex;
    gap: 10px;
}

.input-childgroup div{
    /* background-color: aquamarine; */
    width: 100%;
}