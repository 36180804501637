#unit-main{
    display: flex;
    flex-direction: column;
    overflow: auto;
    align-items: center;
    max-height: calc(100vh - 50px);
    width: 100%;
    /* background-color: #154734; */
}

.no-data{
  /* background-color: #154734; */
  text-align: center;
}

.unit-container .search-group{
  /* background-color: #154734; */
  height: 50px;
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin-top: 10px;
  /* align-items: center;
  justify-content: center; */
}

/* .unit-container .search-group .search-button{
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
} */

.unit-container .upload-section{
  /* background-color: #154734; */
  display: flex;
  row-gap: 10px;
  column-gap: 10px;
  width: 50%;
  margin-top: 50px;
}

.unit-container .upload-section input{
  width: auto;
  border: none;
}

.unit-container h2{
  display: flex;
  align-self: flex-start;
  /* background-color: #154734; */
  margin: 0;
}



/* scroll set start */

.list-antrian::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  .list-antrian::-webkit-scrollbar-track {
    background: #154734;
  }
  
  .list-antrian::-webkit-scrollbar-thumb {
    background-color: #f3f0dd;
    border-radius: 6px;
    border: 3px solid #154734;
  }

  /* scroll set end */




  /* Tabel Set Start */
 
  .list-antrian{
        overflow-y: auto;
        /* width: 100%; */
        /* background-color: #154734; */
        /* display: flex;
        justify-content: center;
        flex-direction: column; */
        /* align-items: center; */
        margin: 0;
        padding: 0;

  }

  table.greyGridTable {
    /* border: 2px solid #FFFFFF; */
    /* max-width: 100%; */
    min-width: 100%;
    /* text-align:justify; */
    border-collapse: collapse;
    border-bottom: black solid 1px;
    
    /* overflow-x: auto; */
    
    /* position: relative; */
    
  }
  table.greyGridTable td, table.greyGridTable th {
    /* border: 1px solid #000000; */
    /* padding: 3px 4px; */
    /* word-wrap: break-word; */
    /* max-width: 300px; */
    /* min-width: 150px; */
    text-align: start;
    /* min-width: 150px; */
    
  }

  table.greyGridTable td.no {
    /* max-width: 30px;
    min-width: 30px; */
    /* background-color: #154734; */
    text-align: center;
  }

  table.greyGridTable tbody td {
    font-size: 12px;
    line-height: 1.5; 
    width: fit-content;
    height: 50px; 
    
  }
  table.greyGridTable tr:nth-child(even) {
    background: #EBEBEB;
  }
  table.greyGridTable thead {
    background: #fce9e9;
    border-bottom: 4px solid #333333;
    position: sticky;
    top: 0;
  }
  table.greyGridTable thead th {
    font-size: 15px;
    font-weight: bold;
    color: #333333;
    /* background-color: #35b483; */
    border: 1px rgb(174, 174, 174) solid;
    /* text-align: center; */
    text-align: center;
    text-wrap: wrap;
    max-width: 45px;
    /* border-left: 2px solid #333333; */
  }
  table.greyGridTable thead th:first-child {
    border-left: none;
  }
  
  table.greyGridTable tfoot td {
    font-size: 14px;
  }  
  table.greyGridTable tfoot td {
    font-size: 14px;
    background-color: #154734;
  }  
  table.greyGridTable  tbody tr:hover {
    cursor: pointer;
    background-color: #d5dad8;
  }  

  table.greyGridTable  tbody tr.no-hover:hover {
    cursor:unset;
    background-color: #d5dad8;
  } 

  table.greyGridTable  tbody tr.no-hover td span{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items:center ;
    background-color: #d1d1d1;
    border-radius: 8px;
    padding: 5px;
  }

  table.greyGridTable  tbody tr.no-hover td span:hover {
    cursor:pointer;
    /* background-color: #d5dad8; */
    transform: scale(1.2);
    transition: all .2s;
    
  } 


   td button.action{
    width: 55%;
    margin: 10px auto;
  }

  td #choose-button:hover{
    cursor: pointer;
    background: #8c8c8c;
    color: #000000;
  }

  td button.action.cancel{
    background: red;
  }


  td button.action.cancel:hover{
    cursor: pointer;
    background: #8c8c8c;
    color: #000000;
  }
  

  /* Tabel Set End */



  .unit-container{
    /* background-color: aqua; */
    width: 98%;
  }











  /* Modal Set Start */


  /* .profile-content .profile-detail .profile-detailButton .profile-editButton:active .profile-modal{
    display: unset;
} */

 .profile-modal{
    background-color: rgb(184, 187, 180, 0.4);
    height: 100%;
    width: 100%;
    position:fixed;
    top: 0px;
    left: 0px;
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100;
    display: none;
    
    
}

 .profile-modal .profile-modalContent{
    margin-top: 20px;
    background-color: rgb(184, 187, 180);
    padding: 20px;
    width: 50%;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0 0 30px 0 ;
    overflow-y: auto;
    align-items: center;

}

.profile-modal .profile-modalContent::-webkit-scrollbar {
  width: 11px;
}

.profile-modal .profile-modalContent::-webkit-scrollbar-track {
  background: #154734;
}

.profile-modal .profile-modalContent::-webkit-scrollbar-thumb {
  background-color: #f3f0dd;
  border-radius: 6px;
  border: 3px solid #154734;
}

 .profile-modal .profile-editForm{
    display: flex;
    flex-direction: column;
    /* background-color: #FFFFFF; */
    width: 90%;
}

.profile-modal .profile-editForm label{
  font-weight: bold;
}

 .profile-modal h1{
    font-size: larger;
    font-weight: 700;
    margin-bottom: 10px;
}

.profile-modalContent input,select,.form-button{
  width: 100%;
}

.profile-modalContent .close-button{
  width: 100%;
  /* background-color: #154734; */
  display: flex;
  justify-content: end;
}

 .profile-modal .profile-editForm input,select{
    margin-bottom: 20px;
}

 .profile-modal .profile-editForm input,select{
    border-radius: 8px;
    height: 30px;
    background-color: rgb(233, 232, 232);
    max-width: none;
}

.profile-modal .profile-editForm input:disabled{
  border-radius: 8px;
  border-color:blue;
  background-color: rgb(217, 217, 217);
}

 .profile-modal .profile-editForm .form-button{
    /* background-color: aqua; */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 10px;
}

 .profile-modal .profile-editForm .form-button button{
    /* background-color: aqua; */
    width: 35%;
    font-weight: 700;
    color: rgb(233, 232, 232);
}

 .profile-modal .profile-editForm .form-button .profile-save{
    background-color: rgb(6, 71, 17);
}
 .profile-modal .profile-editForm .form-button .tab{
    background-color: rgb(5, 150, 150);
}
 .profile-modal .profile-editForm .form-button .tab.active{
    background-color: rgb(5, 150, 150);
    transform: scale(1.2);
    transition: 500ms;
    box-shadow: 0 0 2px 2px white;
    
}

.profile-modal .profile-editForm .form-button .profile-cancel{
    background: rgb(94, 124, 39);
}

.profile-editForm .cancel-btn{
  background: red;
  color: #f3f0dd;
}
.profile-editForm .cancel-btn:hover{
  background: rgb(165, 33, 33);
  color: #f3f0dd;
}

@media  screen and (max-width:1024px) {
    
   .profile-modal .profile-modalContent{
      width: 40%;
  }

  

 
  


}

@media  screen and (max-width:700px){
   .profile-modal .profile-modalContent{
      width: 70%;
  }
}

@media  screen and (max-width:568px){

  

  
  
  .profile-modal .profile-modalContent{
      width: 80%;
      height: 200px;
      overflow: scroll;
  }

   .profile-modal .profile-editForm .form-button button{
      width: 45%;
  }


}

@media  screen and (max-width:430px){

  .profile-modal .profile-modalContent{
      width: 80%;
      height: fit-content;
      overflow: hidden;
  }

  .profile-modal .profile-editForm .form-button button{
      width: 45%;
  }

   .profile-modal .profile-modalContent{
      width: 90%;
  }

   

}


  /* Modal Set End */