.container.s{
    display: flex;
    flex-direction: column;
    width: 100%;
    /* position: relative; */
}

.container.s .layout{
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100%;
}

.sidebar .top_section{
    display: flex;
    justify-content: center;
    margin: 55px 0 20px 0;
}

.home-direct:hover {
    cursor: pointer;
}

.sidebar .sidebar-title{
    /* background-color: rgb(255, 102, 0); */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    /* gap: 20px; */

}



.sidebar{
    background: #22242e;
    color: #fff;
    height: 100vh; /* Tetapkan tinggi yang tetap untuk sidebar */
    overflow-y: auto; 
    width: 200px;
    transition: all 0.5s;
}


.sidebar::-webkit-scrollbar {
    width: 11px;
  }
  
  .sidebar::-webkit-scrollbar-track {
    background: #154734;
  }
  
  .sidebar::-webkit-scrollbar-thumb {
    background-color: #f3f0dd;
    border-radius: 6px;
    border: 3px solid #154734;
  }


.top_section{
    display: flex;
    align-items:center;
    padding:0px 15px;
}
.logo{
    font-size: 30px;
    width: 80px;
}
.bars{
    display: flex;
    font-size: 25px;
    margin-left: 50px;
}

.bars:hover{
    cursor: pointer;
}

.link{
    display: flex;
    color: #fff;
    padding: 10px 15px;
    gap: 15px;
    transition: all 0.5s;
}

.link:hover{
    background:lightskyblue;
    color: #000;
    transition: all 0.5s;
}

.sublink{
    display: flex;
    color: #fff;
    gap: 15px;
    transition: all 0.25s;
    max-height: 0;
    overflow: hidden;
    margin-left: 10px;
}

.sublink.slide{
    max-height: fit-content;
    padding: 10px 15px;
}

.sublink:hover{
    background:#87cefa;
    color: #000;
    transition: all 0.5s;
    cursor: pointer;
}
.active{
    background: lightskyblue;
    color: #000;
}

/* .icon, .link_text{
    font-size: 20px;
} */

.logout.nav-bot{
    display: flex;
    flex-direction: row;
    /* background-color: aqua; */
    width: 100%;
    justify-content: center;
}

.bot{
    display: flex;
    flex-direction: column;
    /* background-color: aqua; */
    align-items: center;
    gap: 50px;
}

.logout.nav-bot:hover{
    cursor: pointer;
}


.nav-group{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* overflow-y: auto; */
    /* height: 80%; */
}