#login-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    /* background-color: #696b6e28; */
    /* background-image: url('../../Asset/Image/Background/Login\ Background\ 3.png');
    background-repeat: repeat-y;
    background-size:cover;
    background-clip: border-box;
    background-position: center; */
}

.box{
    margin: 30px 0 10px 0;
    border: 1px solid black;
}


/* logo Style */
#logo-box{
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
}

#logo-box img{
    width: 300px;
    
}

#logo-box h2{
    font-size: 20px;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color: rgb(48, 48, 48);
}


/* Login Box Style */
#login-box{
    width: 290px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 10px;
    align-items: center;
    box-shadow: 0 0 10px 1px;
    background-color: #54595f4f;

}

#login-box h3{
    font-size: 20px;
    margin-bottom: 15px;
    /* font-family: 'Righteous', cursive; */
    font-weight: 700;
    color: rgb(48, 48, 48);
}

#login-box form{
    width: 100%;
}

/* #login-box form label{
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    width: 95% ;
} */

/* #login-box form label input{
    margin-top: 5px;
    border: 0;
    
    height: 20px;
    width: 100%;
    background-color: whitesmoke;
    border-radius: 10px;
} */

#login-box .button-group{
    /* background-color: aquamarine; */
    width: 100%;
}

.button-group button{
    height: 30px;
    width: 100px;
    background-color: rgb(105, 105, 105);
    border: solid 0.5px rgb(78, 78, 78) ;
    border-radius: 10px;
    font-weight: bold;
    color: rgb(223, 222, 222);
    box-shadow: rgb(105, 105, 105) 0 0 10px 1px  ;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-group #link-button{
    text-decoration: none;
}

.button-group button:hover{
    cursor: pointer;
}


















/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
} */

.tengah img {
  	display: block;
 	margin-left: auto;
 	margin-right: auto;
  	width: 270px;

}

.img-responsive {
    width: 100%;
    height: auto;
}

.login-body {
	display: flex;
	flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #9b9b9b;
    width: 100%;
}

.container-login {
    /* height: 350px; */
    width: 350px;
    background: #0a0222c4;
    border-radius: 15px;
    
    margin-top: 20px;
}

.login {
    width: 350px;
	/* height: 250px; */
}

.login-form {
    width: 250px;
    margin: 25px auto;
}

.login-form h1, h2 {
	
    margin: 20px;
    text-align: center;
    font-weight: bolder;
    text-transform: uppercase;
    color: #fff;
}

.login-form hr {
    border-top: 2px solid #ffa12c;
}

.login-form p {
    text-align: center;
    margin: 10px;
}

.login-form label {
    display: block;
    font-size: 16px;
    font-weight: 600;
    padding: 5px;
    color: #fff;
}

.login-form input {
    width: 100%;
    margin: 2px;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid gray;
}

.login-form button {
    padding: 8px;
    width: 252px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 5px;
    background: #ffa12c;
}

.login-form button:hover {
    background: rgb(22, 110, 85);
}



