#home-container{
    display: flex;
    flex-direction: column;
    overflow: auto;
    align-items: center;
    max-height: calc(100vh);
    width: 100%;
}

#home-container .title-page{
    margin-top: 20px;
} 

#home-container .home-group-card{
    width: 100%;
    /* background-color: cornsilk; */
    margin: 10px auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 150px;
}

.home-group-card .card-link{
    display: flex;
    flex-direction: column;
    width: 300px;
    /* background-color: rgb(255, 102, 0); */
    justify-content: center;
    align-items:center;
    border-radius: 10px;
    box-shadow: black 0 0 8px 1px;
    min-height: 180px;
}

.card-link:nth-child(1n+0){
    background: rgb(255, 102, 0);
}

.card-link:nth-child(2n+0){
    background: rgb(163, 199, 2);
}
.card-link:nth-child(3n+0){
    background: rgb(3, 123, 202);
}
.card-link:nth-child(4n+0){
    background: rgb(167, 3, 153);
}
.card-link:nth-child(5n+0){
    background: rgb(158, 10, 47);
}
.card-link:nth-child(6n+0){
    background: rgb(6, 11, 142);
}
.card-link:nth-child(7n+0){
    background: rgb(25, 92, 0);
}

.card-link h3{
    color: #ffffff;
}

#home-container .link-button{
    text-decoration: none;
    color: white;
    /* border: 1px solid black; */
    padding: 10px;
    border-radius:10px ;
    background-color: rgb(54, 54, 54);
    box-shadow: rgb(97, 92, 92) 0px 0px 4px 2px;
}

#home-container .link-button:hover{
    box-shadow: rgb(97, 92, 92) 0px 0px 10px 4px;
    transition: 500ms;
}


/* Scroll Start */

#home-container::-webkit-scrollbar {
    width: 11px;
  }
  
  #home-container::-webkit-scrollbar-track {
    background: #154734;
  }
  
  #home-container::-webkit-scrollbar-thumb {
    background-color: #f3f0dd;
    border-radius: 6px;
    border: 3px solid #154734;
  }

  /* Scroll End */