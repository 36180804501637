#setting{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    margin-top: 50px;
    /* background-color: blue; */
}

.set-all{
    width: 95%;
    /* background-color: aqua; */
}

.set-all h3{
    /* background-color: aqua; */
    border-bottom: 1px solid black;
}

.list-set{
    display: flex;
    /* background-color: aqua; */
    gap: 50px;
    flex-wrap: wrap;
}

.list-set li{
    cursor:pointer;
    font-weight: 600;
    /* background-color: aqua; */
    box-shadow: 0 0 5px 1px;
    border-radius: 7px;
    height: 20px;
    padding: 0 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}