.header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgb(98, 98, 98);
    height: 50px;
    display: flex;
    align-items: center;
    z-index: 1;
    display: flex;
    justify-content:space-between;

}

.header .logo-head{
    height: 50px;
    /* background-color: aqua; */
    width: 60px;
    display: flex;
    justify-content: center;
    margin-left: 30px;
}

.logo-head img{
    width: 100%;
}

.header .nav-head{
    /* background-color: blue; */
    width: max-content;
    margin-right: 30px;
    display: flex;
    gap: 20px;
}

.nav-head .full-datetime{
    /* background-color: aqua; */
    color: white;
    display: flex;
    align-items: center;
}

.nav-head .welcome-text{
    /* background-color: blue; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.nav-head .welcome-text h5{
    color: aliceblue;
    font-size: 10px;
}

.header .nav-head .logout-icon{
    /* background-color: aqua; */
    height: 40px;
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: rgb(83, 83, 83);
    /* border-radius: 8px; */
}

.nav-head text{
    color: white;
}

.header .nav-head .logout-icon:hover{
    cursor: pointer;
}