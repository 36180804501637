#cetak-container{
    margin-top: 100px;
    width: 100%;
    /* background: rgb(255, 255, 255); */
    /* border: 2px solid black; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

#cetak-container h1, h2, h3, h4, h5, text, p, td, li{
    font-family: 'Josefin Sans', sans-serif;
font-family: 'Outfit', sans-serif;
color: black;
font-weight: 400;
}

#cetak-container .invoice-title{
    font-size: 18px;
    margin-bottom: 20px;
    align-self: flex-end;
    margin-right: 230px;
}

#cetak-conteiner text, p,h3, h4, h5, td{
    font-size: 14px;
}

#cetak-container p{
    letter-spacing: 0.5px;
    line-height: 21px;
}

.detail-info h4{
    font-weight: 600;
}

.cetak-button-container{
    width: 100px;
    background-color: rgb(118, 9, 9);
    margin-top: 25px;
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items:center;
    gap: 10px;
    border: 1px solid black;
    padding: 5px;
    border-radius: 8px;
    
}

.cetak-button-container:hover{
    cursor: pointer;

}

.cetak-button-container text{
    font-size: 23px;
    color: aliceblue;
}

.cetak-button{
    color: aliceblue;
    /* display: block;
    margin-top: 30px;
    font-size: 20px;
    margin-left: 20px;
    background-color: blue;
    padding: 10px; */
}

.cetak-button:hover{
    cursor: pointer;
}



#cetak-container .cetak-head{
    width: 80%;
    display: flex;
    flex-direction: row;
    /* background-color: brown; */
    justify-content: space-between;
    /* border-bottom: 2px solid black; */
}

.cetak-head .cetak-logo{
    width: 150px;
    /* background-color: aqua; */
}

.cetak-head .cetak-refrensi{
    /* background-color: aqua; */
    width: 350px;
}

.cetak-logo img{
    width: 100%;
}

.info-customer{
    width: 80%;
    /* border-bottom: 2px solid black; */
    display: flex;
    flex-direction: row;
    /* background-color: aqua; */
    justify-content: space-between;
    margin-bottom: 50px;
    /* background-color: aqua; */

}

.cetak-infoperusahaan h4{
    font-weight: 600;
}

.info-customer .detail-info{
    width: 350px;
}

.paragraph{
    width: 80%;
    margin: 20px 0;
}

.paragraph p{
    margin-bottom: 20px;
}

.paragraph p.space{
    margin-bottom: 20px;
}

.detail-tagihan{
    width: 95%;
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    border-bottom: 2px solid black;
    /* gap: 10px; */
    
}

.desc-group{
    /* background-color: aquamarine; */
    width: 33.3%;
    /* border-bottom: 1px solid black; */
}

.desc-group h3{
    border-bottom: 1px solid black;
    /* background-color: bisque; */
    width: 100%;
}



.desc-group p{
    margin-top: 20px;
    font-size: 18px;
}
.qty-group p{
    margin-top: 20px;
    font-size: 18px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.qty-group{
    /* background-color: aqua; */
    width: 33.3%;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
}

.qty-group h3{
    border-bottom: 1px solid black;
    /* background-color: bisque; */
    width: 100%;
    display: flex;
    justify-content: center;
}

.harga-group{
    width: 33.3%;
    /* background-color: aqua; */
}

.harga-group h3{
    border-bottom: 1px solid black;
    /* background-color: bisque; */
    width: 100%;
    display: flex;
    justify-content:flex-end;
}

.harga-group p{
    margin-top: 20px;
    font-size: 18px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.total {
    width: 79.9%;
    margin-top: .5px;
    display: flex;
    flex-direction: row;
    /* border-bottom: 2px solid black; */
    justify-content: end;
    /* background-color:blueviolet; */
    border: 1px black solid;
    border-top: 1px black solid;
}

.null{
    width: 33.3%;
}

.desc-total{
    /* background-color: rgb(149, 166, 160); */
    width: 83.6%;
    /* border-left: black 1px solid; */
    display: flex;
    flex-direction: column;
   
}

.desc-total h3{
    /* background-color: bisque; */
    /* width: 50%; */
    display: flex;
    justify-content: end;
    padding: 0 20px;
    margin : 0px 0;
    align-items: center;
    border-bottom: 1px solid black;
    height: 30px;
    font-weight: 600;
    /* background-color: aquamarine; */
   
}

.desc-total h3.grand{
    justify-content: center;
    border-bottom: none;
}

.total-nominal {
    width: 18.2%;
    border-left: 1px black solid;
    display: flex;
    flex-direction: column;
    /* background-color: brown; */

}

.total-nominal h3{
    /* width: 50%; */
    display: flex;
    justify-content: space-between;
    margin : 0px 0;
    border-bottom: 1px solid black;
    align-items: center;
    padding: 0 5px;
    height: 30px;
    font-size: 14px;
    font-weight: 600;

    /* background-color: aqua; */

}

.total-nominal h3.grand{
    border-bottom: none;
}

.note-invoice{
    margin: 0px 0;
    width: 79.9%;
    display: flex;
    flex-direction: column;
    border: 1px black solid;
    border-top: none;
    border-bottom: none;
    /* align-items: center; */
    
}

.note-invoice p, h4{
    margin-left: 2px;
}

.note-invoice h4{
    font-weight: 500;
}

.note-invoice .bank-container{
    /* background-color: aqua; */
    display: flex;
    margin-top: 10px;
    margin-left: 50px;
}

.note-invoice .bank-detail{
    margin-right: 50px;
}

.note-invoice .spelled{
    /* background-color: aqua; */
    width: 100%;
    border-bottom: 1px black solid;
}

.spelled p{
    margin: 10px 30px;
    font-style: italic;
    font-family:'Times New Roman', Times, serif;
    word-spacing: 3px;
    
}

.sign{
    width: 79.9%;
    margin-bottom: 20px;
    border: 1px black solid;
    border-top: none;
}

.sign ul{
    line-height: 30px;
    margin-left: 30px;
    margin-top: 50px;
}

.sign ul li{
    font-size: 14px;
}






/* tabel style */


.header-desc{
    width: 80%;
    /* background-color: aqua; */
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* border: 1px black solid; */
    border-bottom: none;
}

.header-desc text{
    border: 1px black solid;
    border-left: none;
    border-bottom: 1px black solid;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
}

.header-desc text.no{
    width: 15%;
    border-left: 1px black solid;

}

.header-desc text.persen{
    width: 40%;
}

.body-desc text.persen{
    width: 40%;
}

.header-desc text.keterangan{
    width: 200%;
}

.body-desc{
    width: 80%;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    /* border: 1px black solid; */
}

.body-desc text{
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* border: 1px black solid; */
    border-left: none;
    border-right: 1px black solid;
    /* border-bottom: 1px black solid; */
    align-items: flex-start;
    padding: 10px;
}

.body-desc text h5{
    font-weight: 300;
}

.body-desc text.no{
    width: 15%;
    border-left: 1px black solid;
    justify-content: center;

    
}

.body-desc text.persen{
    justify-content: center;
}

.body-desc text.keterangan{
    width: 200%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 500;
}

.body-desc h5.periode-desc{
    font-style: italic;
}

.body-desc.inv text.keterangan{
    min-height: 80px;
    /* align-items: flex-start; */
    /* background-color: aqua; */
    justify-content: flex-start;
}