*{
    margin:0;
    padding:0;
    text-decoration: none;
}
.container{
    display:flex;
}
main{
    width: 95%;
    /* padding: 20px; */
    overflow-y: visible;
    /* position: relative; */
}


